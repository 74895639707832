import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from 'src/i18n'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'it',
  messages,
  silentTranslationWarn: true
})

export default ({ app, store }) => {
  // Set i18n instance on app
  // store.dispatch('traductions/loadTraductions')
  app.i18n = i18n
}

export { i18n }
