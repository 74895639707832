import axios from 'axios'
import { date } from 'quasar'

const valueImportoArray = {
  0: 0,
  1: 10000,
  2: 40000,
  3: 150000,
  4: 500000,
  5: 1000000,
  6: 999999999
}

const getDefaultState = () => {
  return {
    tenderResult: {
      data: [],
      loading: false,
      loaded: false
    },
    tenderResultCard: {
      data: [],
      loading: false,
      loaded: false
    }
  }
}

let cancelTokenSourceTenderResult
let cancelTokenSourceTenderResultCard

// initial state
const state = getDefaultState()

const mutations = {
  resetStateTenderResult (state) {
    Object.assign(state, getDefaultState())
  },
  setDataTenderResult (state, value) {
    state.tenderResult.data = value
  },
  setLoadingTenderResult (state, value) {
    state.tenderResult.loading = value
  },
  setLoadedTenderResult (state, value) {
    state.tenderResult.loaded = value
  },
  setDataTenderResultCard (state, value) {
    state.tenderResultCard.data = value
  },
  setLoadingTenderResultCard (state, value) {
    state.tenderResultCard.loading = value
  },
  setLoadedTenderResultCard (state, value) {
    state.tenderResultCard.loaded = value
  }
}

const actions = {
  resetStateTenderResult ({ commit }) {
    commit('resetStateTenderResult')
  },
  loadData ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingTenderResult', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceTenderResult) {
      cancelTokenSourceTenderResult.cancel()
    }
    // Get a new token
    cancelTokenSourceTenderResult = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let statePagination = rootState.paginations.tenderResult
    let stateFilter = rootState.filters.tenderResult

    let query = {
      query: {
        $or: [
          {
            oggetto: {
              $like: stateFilter.cigOggetto
            }
          },
          {
            cig: {
              $like: stateFilter.cigOggetto
            }
          }
        ],
        nome_ente: {
          $like: stateFilter.nome_ente
        },
        aggiudicatario: {
          $like: stateFilter.aggiudicatario
        },
        procedura: {
          $or: stateFilter.procedura
        },
        type_ente: {
          $or: stateFilter.type_ente
        },
        cat_prevalente: {
          $like: stateFilter.cat_prevalente
        },
        source: {
          $or: stateFilter.source
        },
        data_aggiudicazione: [],
        importo: []
      }
    }
    if (stateFilter.importo) {
      query.query.importo.push({ $gte: valueImportoArray[stateFilter.importo.min] })
      query.query.importo.push({ $lte: valueImportoArray[stateFilter.importo.max] })
    }
    if (stateFilter.data_aggiudicazione_from) {
      query.query.data_aggiudicazione.push({ $gte: stateFilter.data_aggiudicazione_from })
    }
    if (stateFilter.data_aggiudicazione_to) {
      let dateTo = new Date(stateFilter.data_aggiudicazione_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.data_aggiudicazione.push({ $lte: dateTo })
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceTenderResult.token,
      withCredentials: true
    }

    let url = stateSettingUrl.networkpa_tender_result_all
    return axios.get(url, config)
      .then((response) => {
        commit('setDataTenderResult', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'tenderResult',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingTenderResult', false)
        commit('setLoadedTenderResult', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataTenderResult', [])
          commit('setLoadingTenderResult', false)
          return false
        }
      })
  },
  loadDataTenderResultCard ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingTenderResultCard', true)
    if (cancelTokenSourceTenderResultCard) {
      cancelTokenSourceTenderResultCard.cancel()
    }
    // Get a new token
    cancelTokenSourceTenderResultCard = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let statePagination = rootState.paginations.tenderResultCard
    let query = {
      query: {}
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceTenderResultCard.token,
      withCredentials: true
    }
    let url = stateSettingUrl.networkpa_tender_result_all
    return axios.get(url, config)
      .then((response) => {
        commit('setDataTenderResultCard', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'tenderResultCard',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingTenderResultCard', false)
        commit('setLoadedTenderResultCard', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataTenderResultCard', [])
          commit('setLoadingTenderResultCard', false)
          return false
        }
      })
  }

}

const getters = {
  getTenderResult: state => {
    return Object.values(state.tenderResult.data)
  },
  getLoadingTenderResult: state => {
    return state.tenderResult.loading
  },
  getLoadedTenderResult: state => {
    return state.tenderResult.loaded
  },
  getTenderResultCard: state => {
    return Object.values(state.tenderResultCard.data)
  },
  getLoadingTenderResultCard: state => {
    return state.tenderResultCard.loading
  },
  getLoadedTenderResultCard: state => {
    return state.tenderResultCard.loaded
  },
  getValueImporto: state => key => {
    return valueImportoArray[key]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
