import { Dialog, Loading, openURL } from 'quasar'
import { i18n } from 'boot/i18n'
import helpers from 'mixins/mixin-helpers'

export function showLoginErrorMessage () {
  var textTitle = '<div class="text-center"><strong class="">' + i18n.t('auth.msg_login_error_title') + '</strong></div>'
  var textMessage = '<div class="text-center"><strong class="text-red">' + i18n.t('auth.msg_login_error_message') + '</strong></div>'
  Loading.hide()
  Dialog.create({
    title: textTitle,
    message: textMessage,
    html: true
  })
}

export function showLoginErrorExpired () {
  var textTitle = '<div class="text-center"><strong class="text-red">' + helpers.methods.i18n('msg_login_error_title_expired') + '</strong></div>'
  var textMessage = '<div class="text-center"><strong class="">' + helpers.methods.i18n('msg_login_error_message_expired') + '</strong></div>'
  Loading.hide()
  Dialog.create({
    title: textTitle,
    message: textMessage,
    html: true,
    ok: {
      flat: true,
      outline: true,
      label: helpers.methods.i18n('lbl_close')
    },
    cancel: {
      label: helpers.methods.i18n('Change password'),
      icon: 'fas fa-key',
      color: 'secondary'
    },
    persistent: true,
    focus: 'none'
  }).onOk(() => {
    // console.log('>>>> OK')
  }).onCancel(() => {
    let url = process.env.ECOMERCE_URL + process.env.ECOMERCE_URL_RESET_PWD
    openURL(url)
  }).onDismiss(() => {
    // console.log('I am triggered on both OK and Cancel')
  })
}
