import { Cookies } from 'quasar'
// import { showErrorMessage } from 'src/functions/function-show-error-message'

const state = {
  // loggedIn: false
}

const mutations = {}

const actions = {
  getAllCookies () {
    const cookies = Cookies.getAll()
    // eslint-disable-next-line no-console
    console.log('Cookie: ', cookies)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
