import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('installations', [
      'setPrefer',
      'setDisableSelectRow',
      'setStatusSyncroAccount',
      'setStatusSyncroIscrizione',
      'loadInstallations'
    ])
  },
  computed: {
    ...mapGetters('installations', [
      'getStateInstallations',
      'getStateInstallationsCount',
      'getStateInstallationsAll',
      'getStateInstallationsAllCount',
      'getStateInstallationsToSyncro',
      'getStateInstallationsToSyncroCount',
      'getStateInstallationsCanEnable',
      'getStateInstallationsCanEnableCount',
      'getStateInstallationsInValidation',
      'getStateInstallationsInValidationCount',
      'getStateInstallationsValidated',
      'getStateInstallationsValidatedCount',
      'getStateInstallationsExpiring',
      'getStateInstallationsExpiringCount',
      'getStateInstallationsExpired',
      'getStateInstallationsExpiredCount',
      'getStateInstallationsWithSyncroSubscription',
      'getStateInstallationsWithSyncroSubscriptionCount',
      'getStateInstallationsPrefer',
      'getStateInstallationsPreferCount',
      'getPlatformById'
    ])
  }
}
