import axios from 'axios'
import { date } from 'quasar'

const getDefaultState = () => {
  return {
    infoBandi: {
      data: [],
      loading: false,
      loaded: false
    },
    infoBandiCard: {
      data: [],
      loading: false,
      loaded: false
    }
  }
}

let cancelTokenSourceInfoBandi
let cancelTokenSourceInfoBandiCard

// initial state
const state = getDefaultState()

const mutations = {
  resetStateInfoBandi (state) {
    Object.assign(state, getDefaultState())
  },
  setDataInfoBandi (state, value) {
    state.infoBandi.data = value
  },
  setLoadingInfoBandi (state, value) {
    state.infoBandi.loading = value
  },
  setLoadedInfoBandi (state, value) {
    state.infoBandi.loaded = value
  },
  setDataInfoBandiCard (state, value) {
    state.infoBandiCard.data = value
  },
  setLoadingInfoBandiCard (state, value) {
    state.infoBandiCard.loading = value
  },
  setLoadedInfoBandiCard (state, value) {
    state.infoBandiCard.loaded = value
  }
}

const actions = {
  resetStateInfoBandi ({ commit }) {
    commit('resetStateInfoBandi')
  },
  loadData ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingInfoBandi', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceInfoBandi) {
      cancelTokenSourceInfoBandi.cancel()
    }
    // Get a new token
    cancelTokenSourceInfoBandi = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilter = rootState.filters.infoBandi
    let statePagination = rootState.paginations.infoBandi
    let query = {
      query: {
        $or: [
          {
            oggetto: {
              $like: stateFilter.cigOggetto
            }
          },
          {
            cig: {
              $like: stateFilter.cigOggetto
            }
          }
        ],
        data_pubblicazione: [],
        nome_ente: {
          $like: stateFilter.nome_ente
        },
        procedura: {
          $or: stateFilter.procedura
        },
        cat_prevalente: {
          $like: stateFilter.cat_prevalente
        },
        type_ente: {
          $or: stateFilter.type_ente
        },
        regione: {
          $or: stateFilter.regione
        },
        source: {
          $or: stateFilter.source
        }
      }
    }
    if (stateFilter.data_pubblicazione_from) {
      query.query.data_pubblicazione.push({ $gte: stateFilter.data_pubblicazione_from })
    }
    if (stateFilter.data_pubblicazione_to) {
      let dateTo = new Date(stateFilter.data_pubblicazione_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.data_pubblicazione.push({ $lte: dateTo })
    }
    if (stateFilter.is_expiring) {
    } else {
      if (typeof query.query['$havingor'] === 'undefined') {
        query.query = {
          ...query.query,
          ...{ '$havingor': [] }
        }
      }
      query.query['$havingor'].push({ 'is_expiring': '0' })
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceInfoBandi.token,
      withCredentials: true
    }
    let url = stateSettingUrl.networkpa_info_bandi_all
    return axios.get(url, config)
      .then((response) => {
        commit('setDataInfoBandi', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'infoBandi',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingInfoBandi', false)
        commit('setLoadedInfoBandi', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataInfoBandi', [])
          commit('setLoadingInfoBandi', false)
          return false
        }
      })
  },
  loadDataInfoBandiCard ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoadingInfoBandiCard', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceInfoBandiCard) {
      cancelTokenSourceInfoBandiCard.cancel()
    }
    // Get a new token
    cancelTokenSourceInfoBandiCard = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let statePagination = rootState.paginations.infoBandiCard
    let query = {
      query: {}
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          'prefer DESC',
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceInfoBandiCard.token,
      withCredentials: true
    }
    let url = stateSettingUrl.networkpa_info_bandi_all
    return axios.get(url, config)
      .then((response) => {
        commit('setDataInfoBandiCard', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'infoBandiCard',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoadingInfoBandiCard', false)
        commit('setLoadedInfoBandiCard', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setDataInfoBandiCard', [])
          commit('setLoadingInfoBandiCard', false)
          return false
        }
      })
  }
}

const getters = {
  getInfoBandi: state => {
    return Object.values(state.infoBandi.data)
  },
  getLoadingInfoBandi: state => {
    return state.infoBandi.loading
  },
  getLoadedInfoBandi: state => {
    return state.infoBandi.loaded
  },
  getInfoBandiCard: state => {
    return Object.values(state.infoBandiCard.data)
  },
  getLoadingInfoBandiCard: state => {
    return state.infoBandiCard.loading
  },
  getLoadedInfoBandiCard: state => {
    return state.infoBandiCard.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
