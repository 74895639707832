import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('paginations', [
      'setPaginations',
      'setPaginationsKey'
    ])
  },
  computed: {
    ...mapGetters('paginations', [
      'getPaginations',
      'getPaginationKey',
      'getTypeLoading',
      'getLastFilterApplied'
    ])
  }
}
