import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from 'router/routes'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function ({ store }) {
  // alert(!!window.cordova)

  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.beforeEach(async (to, from, next) => {
    let check = true
    switch (to.name) {
      case 'benchmark-contr':
        check = await store.dispatch('benchmarkcontr/loadDetailsBenchmarkContr', to.params.id)
        break
      case 'benchmark-sa':
        check = await store.dispatch('benchmarksa/loadDetailsBenchmarkSA', to.params.id)
        break
      case 'benchmark-oe':
        check = await store.dispatch('benchmarkoe/loadDetailsBenchmarkOE', to.params.id)
        break
    }

    if (!check) {
      const isLoggedIn = store.getters['auth/getAuthLoggedIn']

      if (!isLoggedIn) {
        next({ path: '/', replace: true })
        return
      }
      next({ path: '/maytheforcebewithyou', replace: true })
    }
    next()
  })

  Router.afterEach(async (to, from, next) => {
    switch (from.name) {
      case 'benchmark-sa':
        store.dispatch('benchmarksa/unloadData', 'benchmarksa')
        break
      case 'benchmark-oe':
        store.dispatch('benchmarkoe/unloadData', 'benchmarkoe')
        break
    }
  })

  return Router
}
