import sharedStoreActions from './sharedStoreActions'

const getDefaultState = () => {
  return {
    benchmark: {
      data: [],
      loading: false,
      loaded: false
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  ...sharedStoreActions.mutations
}

const actions = {
  ...sharedStoreActions.actions
}

const getters = {
  getBenchmark: state => {
    return Object.values(state.benchmark.data)
  },
  getLoadingBenchmark: state => {
    return state.benchmark.loading
  },
  getLoadedBenchmark: state => {
    return state.benchmark.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
