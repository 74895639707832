import BackendAPI from '../classes/backendAPI'
import { url } from '../classes/settingsStatic'
import Vue from 'vue'

const api = new BackendAPI({
  baseURL: url.ws_benchmark_gare,
  withCredentials: true
})
const apiCheck = new BackendAPI({
  baseURL: url.ws_benchmark_query_info,
  withCredentials: true
})
const apiPaginations = new BackendAPI({
  baseURL: url.ws_benchmark_gare_count,
  withCredentials: true
})
export default {
  mutations: {
    setData (state, payload) {
      state[payload.type].data = payload.value
    },
    setLoading (state, payload) {
      state[payload.type].loading = payload.value
    },
    setLoaded (state, payload) {
      state[payload.type].loaded = payload.value
    }
  },
  actions: {
    async loadData ({ commit, rootState, dispatch }, { type = 'benchmark', isPagination = false }) {
      let filters = {}
      if (isPagination) {
        filters = JSON.parse(JSON.stringify(rootState.paginations[type].lastFilterApplied))
      } else {
        filters = JSON.parse(JSON.stringify(rootState.filters[type]))
      }
      let data = {
        pagination: {},
        filter: filters,
        type: type
      }
      let resultCheckQuery = await dispatch('CheckQueryInfo', data)
      if (resultCheckQuery) {
        if (!isPagination) {
          let setPayloadLastFilterApplied = {
            type: type,
            value: JSON.parse(JSON.stringify(rootState.filters[type]))
          }
          dispatch('paginations/setLastFilterApplied', setPayloadLastFilterApplied, { root: true })
          dispatch('paginations/resetForTypePaginationExceptLastFilter', type, { root: true })
        }
        data.pagination = JSON.parse(JSON.stringify(rootState.paginations[type]))
        commit('setLoading', {
          type: type,
          value: true
        })
        dispatch('setLoadingPaginations',
          {
            type: type,
            value: true
          })

        return api.post(undefined, data)
          .then((response) => {
            commit('setData', {
              type: type,
              value: { ...response.data }
            })
            if (response.total > 0) {
              dispatch('setDataPaginations',
                {
                  type: data.type,
                  total: response.total
                }
              )
            } else {
              dispatch('loadDataPaginations', data)
            }
            dispatch('user/loadBenchmark190', true, { root: true })
            commit('setLoaded', {
              type: type,
              value: true
            })
            commit('setLoading', {
              type: type,
              value: false
            })
            return response
          })
          .catch(function (error) {
            console.log('Error loadData for: ' + type, error)
            return false
          })
      }
    },
    async CheckQueryInfo ({ rootGetters }, data) {
      return new Promise((resolve, reject) => {
        apiCheck.post(undefined, data)
          .then((response) => {
            if (response.decrement) {
              if (!rootGetters['permissions/getPermission']('npa.no_dialog_queries')) {
                Vue.prototype.$q.dialog({
                  component: 'DialogQueryDecrease',
                  remainingRequests: response.remainingRequests.afterQuery
                }).onOk(() => {
                  resolve(true)
                }).onCancel(() => {
                  resolve(false)
                })
              } else {
                resolve(true)
              }
            } else {
              if (!response.execute) {
                Vue.prototype.$q.dialog({
                  component: 'DialogQueryFinished',
                  text: response.motivation
                })
                resolve(false)
              } else {
                resolve(true)
              }
            }
          })
          .catch(function (error) {
            console.log('error', error)
            reject(error)
          })
      })
    },
    loadDataPaginations ({ commit, rootState, dispatch }, data) {
      return apiPaginations.post(undefined, data)
        .then((response) => {
          dispatch('setDataPaginations',
            {
              type: data.type,
              total: response.total
            }
          )
        })
        .catch(function (error) {
          console.log('error', error)
        })
    },
    setDataPaginations ({ dispatch }, payload) {
      dispatch('paginations/setPaginationsKey', {
        type: payload.type,
        key: 'rowsNumber',
        value: payload.total
      }, { root: true })
      dispatch('setLoadingPaginations',
        {
          type: payload.type,
          value: false
        }
      )
    },
    setLoadingPaginations ({ dispatch }, payload) {
      dispatch('paginations/setTypeLoading', {
        type: payload.type,
        value: payload.value
      }, { root: true })
    },
    unloadData ({ commit, dispatch }, type) {
      commit('setLoaded', {
        type: type,
        value: false
      })
      commit('setData', {
        type: type,
        value: {}
      })
      dispatch('paginations/resetForTypePagination', type, { root: true })
    }
  }
}
