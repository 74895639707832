<template>
  <div id="q-app">
    <LoadingScreen/>
    <div>
      <transition
        mode="out-in"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
      <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import helpers from 'mixins/mixin-helpers'

export default {
  mixins: [
    helpers
  ]
}
</script>
