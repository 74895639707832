import Vue from 'vue'
import Vuex from 'vuex'

import settings from 'store/store-settings'
import user from 'store/store-user'
import supplierForms from 'store/store-supplier-forms'
import installations from 'store/store-installations'
import syncro from 'store/store-syncro'
import documents from 'store/store-documents'
import tenders from 'store/store-tenders'
import permissions from 'store/store-permissions'
import auth from 'store/auth'
import dialogs from 'store/store-dialogs'
import session from 'store/auth-session'
import cookie from 'store/auth-cookie'
import notify from 'store/store-notify'
// NEW
import filters from 'store/store-filters'
import paginations from 'store/store-paginations'
import benchmark from 'store/store-benchmark'
import benchmarkcontr from 'store/store-benchmark-contr'
import benchmarkoe from 'store/store-benchmark-oe'
import benchmarksa from 'store/store-benchmark-sa'
import tenderResult from 'store/store-tenderResult'
import infoBandi from 'store/store-infoBandi'
import messages from 'store/store-messages'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      settings,
      user,
      supplierForms,
      installations,
      syncro,
      dialogs,
      documents,
      permissions,
      tenders,
      messages,
      auth,
      session,
      cookie,
      notify,
      filters,
      paginations,
      benchmark,
      benchmarkcontr,
      benchmarkoe,
      benchmarksa,
      tenderResult,
      infoBandi
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
