import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('infoBandi', [
      'loadData',
      'loadDataInfoBandiCard'
    ])
  },
  computed: {
    ...mapGetters('infoBandi', [
      'getInfoBandi',
      'getLoadingInfoBandi',
      'getLoadedInfoBandi',
      'getInfoBandiCard',
      'getLoadingInfoBandiCard',
      'getLoadedInfoBandiCard'
    ])
  }
}
