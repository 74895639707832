import BackendAPI from '../classes/backendAPI'
import sharedStoreActions from './sharedStoreActions'
import { url } from '../classes/settingsStatic'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    benchmarkoe: {
      data: [],
      loading: false,
      loaded: false,
      details: {},
      loadDetailsOE: false
    }
  }
}

const apiDetails = new BackendAPI({
  baseURL: url.ws_benchmark_operatori_economici
})

// initial state
const state = getDefaultState()

const mutations = {
  ...sharedStoreActions.mutations,
  setDetailsBenchmarkOE (state, value) {
    state.benchmarkoe.details = value
  },
  setLoadDetailsOE (state, value) {
    state.benchmarkoe.loadDetailsOE = value
  }
}

const actions = {
  ...sharedStoreActions.actions,
  loadDetailsBenchmarkOE ({ commit, rootState, dispatch }, id) {
    commit('setLoadDetailsOE', true)

    return apiDetails.get(id + '/info')
      .then((response) => {
        dispatch('user/loadBenchmark190', true, { root: true })
        // reperisco tutti gli anni
        let years = [...new Set(
          response.importi.filter(obj => obj.anno_aggiudicazione !== null)
            .map(obj => obj.anno_aggiudicazione)
        )]
        let payload = {
          // denominazione: response.varianti[0].denominazione,
          denominazione: response.denominazione,
          contratti_in_scadenza: response.contratti_in_scadenza,
          years: response.importi.filter(obj => obj.range_importo === null && years.includes(obj.anno_aggiudicazione)),
          total: response.importi.find(obj => obj.range_importo === null && obj.anno_aggiudicazione === null)
        }
        commit('setDetailsBenchmarkOE', { ...payload })
        commit('setLoadDetailsOE', false)
        return true
      })
      .catch(function (error) {
        const message = error.message
        const response = error.response

        if (response !== undefined) {
          if (response.status !== 499) {
            Vue.prototype.$q.dialog({
              component: 'DialogQueryFinished',
              parent: this,
              text: message
            })
            commit('setDetailsBenchmarkOE', {})
            commit('setLoadDetailsOE', false)
          }
        } else {
          console.log(error)
        }

        return false
      })
  }
}

const getters = {
  getBenchmarkOE: state => {
    return Object.values(state.benchmarkoe.data)
  },
  getLoadingBenchmarkoe: state => {
    return state.benchmarkoe.loading
  },
  getLoadedBenchmarkoe: state => {
    return state.benchmarkoe.loaded
  },
  getDetailsBenchmarkOE: state => {
    return state.benchmarkoe.details
  },
  getLoadDetailsOE: state => {
    return state.benchmarkoe.loadDetailsOE
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
