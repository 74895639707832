import permissions from 'store/store-permissions'

export const dpaPermissions = {
  bind: function (el, binding, vnode) {
    let keyPermission = binding.value
    let permissionsData = permissions.state.permissions.data
    if (permissionsData.includes(keyPermission + '_hidden')) {
      vnode.context.dpaHidden()
    }
    if (permissionsData.includes(keyPermission + '_visible')) {
      vnode.context.dpaVisible()
    }
    if (permissionsData.includes(keyPermission + '_disabled')) {
      vnode.context.dpaDisable()
    }
    if (permissionsData.includes(keyPermission + '_enabled')) {
      vnode.context.dpaEnable()
    }
  }
}
