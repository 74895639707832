export default class ActiveServices {
  constructor (data) {
    this.services = data.activeServices
    this.reachedMaxRequests = data.reachedMaxRequests
    this.remainingRequests = data.remainingRequests
    this.availableRequests = data.availableRequests
  }

  // Restituisce l'oggetto servizio attivo (escluso benchmark)
  getActiveService () {
    let activeServices = Object.keys(this.services).filter(service => {
      return this.services[service].active && service !== 'benchmark'
    })
    let serviceName = activeServices.pop()
    return this.services[serviceName]
  }

  // Restituisce l'oggetto servizio benchmark
  getBenchmarkService () {
    let activeServices = Object.keys(this.services).filter(service => {
      return this.services[service].active && (service === 'benchmark' || service === 'unlimited')
    })
    let serviceName = activeServices.pop()
    return this.services[serviceName]
  }

  // Restituisce il nome del servizio attivo
  getServiceName () {
    let activeService = this.getActiveService()
    return activeService.code
  }

// Restituisce il nome del servizio benchmark
  getBenchmarkName () {
    let benchmarkService = this.getBenchmarkService()
    return benchmarkService.code
  }

  // Restituisce il numero di requests di partenza
  getAvailableRequests () {
    return this.availableRequests
  }

  // Restituisce le requests disponibilie
  getRemainingRequests () {
    return this.remainingRequests
  }

  // Restituisce true se ha raggiunto il limite massimo di richieste
  isMaxRequestsReached () {
    return this.reachedMaxRequests
  }

  // Restituisce true se il servizio attivo è scaduto
  isServiceExpired () {
    let activeService = this.getActiveService()
    return activeService.expired
  }

  // Restituisce la data di scadenza del servizio attivo nel formato "dd/mm/yyyy"
  getActiveServiceExpirationDate () {
    return this.getExpirationDate(this.getActiveService())
  }

  // Restituisce la data di scadenza del Benchmark nel formato "dd/mm/yyyy"
  getBenchmarkExpirationDate () {
    return this.getExpirationDate(this.getBenchmarkService())
  }

  // Restituisce la data di scadenza del servizio o del benchmark nel formato "dd/mm/yyyy"
  getExpirationDate (service) {
    let expiration = service.expiration
    if (expiration) {
      let expirationDate = new Date(expiration)
      let day = expirationDate.getDate().toString().padStart(2, '0')
      let month = (expirationDate.getMonth() + 1).toString().padStart(2, '0')
      let year = expirationDate.getFullYear().toString()
      return `${day}/${month}/${year}`
    } else {
      return null
    }
  }

  // Restituisce il codice del servizio attivo
  getServiceCode () {
    let activeService = this.getActiveService()
    return activeService.code
  }
}
