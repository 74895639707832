import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('dialogs', [
      'setSyncroDialog',
      'setSyncroSubscriptionDialog',
      'setConfirmSyncroSubscriptionDialog',
      'setDeSyncroDialog',
      'setForUserPlusDialog',
      'setVerifyEmailDialog',
      'setPasswordModifiedDialog',
      'setCredentialSyncroErrorEmailUsedDialog',
      'setCredentialSyncroEmailSentDialog',
      'setCredentialSyncroCompleteDialog',
      'setPaperBoyNewsDialog',
      'setNewsPaperBoyDialog',
      'setDownloadAppDialog'
    ])
  },
  computed: {
    ...mapGetters('dialogs', [
      'getAllDialogs',
      'getSyncroDialog',
      'getSyncroSubscriptionDialog',
      'getConfirmSyncroSubscriptionDialog',
      'getDeSyncroDialog',
      'getForUserPlusDialog',
      'getVerifyEmailDialog',
      'getPasswordModifiedDialog',
      'getCredentialSyncroErrorEmailUsedDialog',
      'getCredentialSyncroEmailSentDialog',
      'getCredentialSyncroCompleteDialog',
      'getPaperBoyNewsDialog',
      'getNewsPaperBoyDialog',
      'getDownloadAppDialog'
    ]),
    syncroDialog: {
      get () {
        return this.getSyncroDialog
      },
      set (value) {
        this.setSyncroDialog(value)
      }
    },
    syncroSubscriptionDialog: {
      get () {
        return this.getSyncroSubscriptionDialog
      },
      set (value) {
        this.setSyncroSubscriptionDialog(value)
      }
    },
    confirmSyncroSubscriptionDialog: {
      get () {
        return this.getConfirmSyncroSubscriptionDialog
      },
      set (value) {
        this.setConfirmSyncroSubscriptionDialog(value)
      }
    },
    deSyncroDialog: {
      get () {
        return this.getDeSyncroDialog
      },
      set (value) {
        this.setDeSyncroDialog(value)
      }
    },
    forUserPlusDialog: {
      get () {
        return this.getForUserPlusDialog
      },
      set (value) {
        this.setForUserPlusDialog(value)
      }
    },
    passwordModifiedDialog: {
      get () {
        return this.getPasswordModifiedDialog
      },
      set (value) {
        this.setPasswordModifiedDialog(value)
      }
    },
    verifyEmailDialog: {
      get () {
        return this.getVerifyEmailDialog
      },
      set (value) {
        this.setVerifyEmailDialog(value)
      }
    },
    credentialSyncroErrorEmailUsedDialog: {
      get () {
        return this.getCredentialSyncroErrorEmailUsedDialog
      },
      set (value) {
        this.setCredentialSyncroErrorEmailUsedDialog(value)
      }
    },
    credentialSyncroEmailSentDialog: {
      get () {
        return this.getCredentialSyncroEmailSentDialog
      },
      set (value) {
        this.setCredentialSyncroEmailSentDialog(value)
      }
    },
    credentialSyncroCompleteDialog: {
      get () {
        return this.getCredentialSyncroCompleteDialog
      },
      set (value) {
        this.setCredentialSyncroCompleteDialog(value)
      }
    },
    paperBoyNewsDialog: {
      get () {
        return this.getPaperBoyNewsDialog
      },
      set (value) {
        this.setPaperBoyNewsDialog(value)
      }
    },
    newsPaperBoyDialog: {
      get () {
        return this.getNewsPaperBoyDialog
      },
      set (value) {
        this.setNewsPaperBoyDialog(value)
      }
    },
    downloadAppDialog: {
      get () {
        return this.getDownloadAppDialog
      },
      set (value) {
        this.setDownloadAppDialog(value)
      }
    }
  }
}
