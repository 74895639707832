import { Dialog, Loading } from 'quasar'

export function showErrorMessage (errorMessage, title = 'Error') {
  var textTitle = '<div class="text-center"><strong class="">' + title + '</strong></div>'
  var textMessage = '<div class="text-center"><strong class="text-red">' + errorMessage + '</strong></div>'
  Loading.hide()
  Dialog.create({
    title: textTitle,
    message: textMessage,
    html: true
  })
}
