import enEN from 'i18n/en/index.json'
import itIT from 'i18n/it/index.json'
import esES from 'i18n/es/index.json'
import deDE from 'i18n/de/index.json'
import frFR from 'i18n/fr/index.json'

import { internalItIT } from 'i18n/it/internal-index.js'
import { internalEnEN } from 'i18n/en/internal-index.js'
import { internalEsES } from 'i18n/es/internal-index.js'
import { internalDeDE } from 'i18n/de/internal-index.js'
import { internalFrFR } from 'i18n/fr/internal-index.js'

export default {
  'en-us': { ...enEN, ...internalEnEN },
  'en-gb': { ...enEN, ...internalEnEN },
  'es': { ...esES, ...internalEsES },
  'it': { ...itIT, ...internalItIT },
  'de': { ...deDE, ...internalDeDE },
  'fr': { ...frFR, ...internalFrFR }
}
