import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('benchmarkoe', [
      'loadData',
      'loadDetailsBenchmarkOE'
    ])
  },
  computed: {
    ...mapGetters('benchmarkoe', [
      'getBenchmarkOE',
      'getLoadingBenchmarkoe',
      'getLoadedBenchmarkoe',
      'getDetailsBenchmarkOE',
      'getLoadDetailsOE'

    ])
  }
}
