const getDefaultState = () => {
  return {
    dialogs: {
      syncro: false,
      syncroSubscription: false,
      confirmSyncroSubscription: false,
      deSyncro: false,
      forUserPlus: false,
      verifyEmail: false,
      passwordModified: false,
      credentialSyncroErrorEmailUsedDialog: false,
      credentialSyncroEmailSentDialog: false,
      credentialSyncroCompleteDialog: false,
      paperBoyNewsDialog: {
        active: false,
        news: {}
      },
      downloadAppDialog: false
    }
  }
}
// initial state
const state = getDefaultState()

const mutations = {
  resetStateDialogs (state) {
    Object.assign(state, getDefaultState())
  },
  setSyncroDialog (state, value) {
    state.dialogs.syncro = value
  },
  setSyncroSubscriptionDialog (state, value) {
    state.dialogs.syncroSubscription = value
  },
  setConfirmSyncroSubscriptionDialog (state, value) {
    state.dialogs.confirmSyncroSubscription = value
  },
  setDeSyncroDialog (state, value) {
    state.dialogs.deSyncro = value
  },
  setForUserPlusDialog (state, value) {
    state.dialogs.forUserPlus = value
  },
  setVerifyEmailDialog (state, value) {
    state.dialogs.verifyEmail = value
  },
  setPasswordModifiedDialog (state, value) {
    state.dialogs.passwordModified = value
  },
  setCredentialSyncroErrorEmailUsedDialog (state, value) {
    state.dialogs.credentialSyncroErrorEmailUsedDialog = value
  },
  setCredentialSyncroEmailSentDialog (state, value) {
    state.dialogs.credentialSyncroEmailSentDialog = value
  },
  setCredentialSyncroCompleteDialog (state, value) {
    state.dialogs.credentialSyncroCompleteDialog = value
  },
  setPaperBoyNewsDialog (state, value) {
    state.dialogs.paperBoyNewsDialog.active = value
  },
  setNewsPaperBoyDialog (state, value) {
    state.dialogs.paperBoyNewsDialog.news = value
  },
  setDownloadAppDialog (state, value) {
    state.dialogs.downloadAppDialog = value
  }
}

const actions = {
  resetStateDialogs ({ commit }) {
    commit('resetStateDialogs')
  },
  setSyncroDialog ({ commit, dispatch }, value) {
    commit('setSyncroDialog', value)
  },
  setSyncroSubscriptionDialog ({ commit, dispatch }, value) {
    commit('setSyncroSubscriptionDialog', value)
  },
  setConfirmSyncroSubscriptionDialog ({ commit, dispatch }, value) {
    commit('setConfirmSyncroSubscriptionDialog', value)
  },
  setDeSyncroDialog ({ commit, dispatch }, value) {
    commit('setDeSyncroDialog', value)
  },
  setForUserPlusDialog ({ commit, dispatch }, value) {
    commit('setForUserPlusDialog', value)
  },
  setVerifyEmailDialog ({ commit, dispatch }, value) {
    commit('setVerifyEmailDialog', value)
  },
  setPasswordModifiedDialog ({ commit, dispatch }, value) {
    commit('setPasswordModifiedDialog', value)
  },
  setCredentialSyncroErrorEmailUsedDialog ({ commit, dispatch }, value) {
    commit('setCredentialSyncroErrorEmailUsedDialog', value)
  },
  setCredentialSyncroEmailSentDialog ({ commit, dispatch }, value) {
    commit('setCredentialSyncroEmailSentDialog', value)
  },
  setCredentialSyncroCompleteDialog ({ commit, dispatch }, value) {
    commit('setCredentialSyncroCompleteDialog', value)
  },
  setPaperBoyNewsDialog ({ commit, dispatch }, value) {
    commit('setPaperBoyNewsDialog', value)
  },
  setNewsPaperBoyDialog ({ commit, dispatch }, value) {
    commit('setNewsPaperBoyDialog', value)
  },
  setDownloadAppDialog ({ commit, dispatch }, value) {
    commit('setDownloadAppDialog', value)
  }
}

const getters = {
  getAllDialogs: state => {
    return state.dialogs
  },
  getSyncroDialog: state => {
    return state.dialogs.syncro
  },
  getSyncroSubscriptionDialog: state => {
    return state.dialogs.syncroSubscription
  },
  getConfirmSyncroSubscriptionDialog: state => {
    return state.dialogs.confirmSyncroSubscription
  },
  getDeSyncroDialog: state => {
    return state.dialogs.deSyncro
  },
  getForUserPlusDialog: state => {
    return state.dialogs.forUserPlus
  },
  getVerifyEmailDialog: state => {
    return state.dialogs.verifyEmail
  },
  getPasswordModifiedDialog: state => {
    return state.dialogs.passwordModified
  },
  getCredentialSyncroErrorEmailUsedDialog: state => {
    return state.dialogs.credentialSyncroErrorEmailUsedDialog
  },
  getCredentialSyncroEmailSentDialog: state => {
    return state.dialogs.credentialSyncroEmailSentDialog
  },
  getCredentialSyncroCompleteDialog: state => {
    return state.dialogs.credentialSyncroCompleteDialog
  },
  getPaperBoyNewsDialog: state => {
    return state.dialogs.paperBoyNewsDialog.active
  },
  getNewsPaperBoyDialog: state => {
    return state.dialogs.paperBoyNewsDialog.news
  },
  getDownloadAppDialog: state => {
    return state.dialogs.downloadAppDialog
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
