import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('messages', [
      'loadData'
    ])
  },
  computed: {
    ...mapGetters('messages', [
      'getAllMessages',
      'getLoadingMessages',
      'getLoadedMessages'
    ])
  }
}
