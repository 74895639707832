import axios from 'axios'
import { date } from 'quasar'

const getDefaultState = () => {
  return {
    messages: {
      data: [],
      loading: false,
      loaded: false
    }
  }
}
let cancelTokenSourceMessages

// initial state
const state = getDefaultState()

const mutations = {
  resetStateMessages (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.messages.data = value
  },
  setLoading (state, value) {
    state.messages.loading = value
  },
  setLoaded (state, value) {
    state.messages.loaded = value
  }
}

const actions = {
  resetStateMessages ({ commit }) {
    commit('resetStateMessages')
  },
  loadData ({ commit, rootState, rootGetters, dispatch }) {
    commit('setLoading', true)
    // Cancel the previous axios token if any
    if (cancelTokenSourceMessages) {
      cancelTokenSourceMessages.cancel()
    }
    // Get a new token
    cancelTokenSourceMessages = axios.CancelToken.source()
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilter = rootState.filters.messages
    let statePagination = rootState.paginations.messages
    let query = {
      query: {
        view_date: [],
        created_at: [],
        nome_ente: {
          $like: stateFilter.nome_ente
        },
        subject: {
          $like: stateFilter.subject
        }
      }
    }
    switch (stateFilter.view_date) {
      case 1:
        query.query.view_date.push({ $not: null })
        break
      case 2:
        query.query.view_date.push({ $is: null })
        break
      case 0:
      default:
        break
    }
    if (stateFilter.created_at_from) {
      query.query.created_at.push({ $gte: stateFilter.created_at_from })
    }
    if (stateFilter.created_at_to) {
      let dateTo = new Date(stateFilter.created_at_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.created_at.push({ $lte: dateTo })
    }
    let config = {
      params: {
        $limit: statePagination.rowsPerPage,
        $offset: (statePagination.page - 1) * statePagination.rowsPerPage,
        $sort: [
          statePagination.sortBy + ' ' + (statePagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      },
      cancelToken: cancelTokenSourceMessages.token,
      withCredentials: true
    }
    let url = stateSettingUrl.networkpa_messages_all
    return axios.get(url, config)
      .then((response) => {
        commit('setData', { ...response.data.data })
        dispatch('paginations/setPaginationsKey', {
          type: 'messages',
          key: 'rowsNumber',
          value: response.data.meta.nb_filtered
        }, { root: true })
        commit('setLoading', false)
        commit('setLoaded', true)
        return response.data
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return { 'response': { 'data': 'Request Canceled for: ' + url } }
        } else {
          commit('setData', [])
          commit('setLoading', false)
          return false
        }
      })
  }
}

const getters = {
  getAllMessages: state => {
    return Object.values(state.messages.data)
  },
  getLoadingMessages: state => {
    return state.messages.loading
  },
  getLoadedMessages: state => {
    return state.messages.loaded
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
