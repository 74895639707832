import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('tenders', [
      'loadData',
      'loadDataPublicCard',
      'loadDataInvitedCard',
      'loadDataSubscriptionCard',
      'setPrefer'
    ])
  },
  computed: {
    ...mapGetters('tenders', [
      'getTenders',
      'getLoadingTenders',
      'getLoadedTenders',
      'getTenderPublicCard',
      'getLoadingPublicCard',
      'getLoadedPublicCard',
      'getTenderInvitedCard',
      'getLoadingInvitedCard',
      'getLoadedInvitedCard',
      'getTenderSubscriptionCard',
      'getLoadingSubscriptionCard',
      'getLoadedSubscriptionCard'
    ])
  }
}
