import { LocalStorage } from 'quasar'
import axios from 'axios'

const getDefaultState = () => {
  return {
    documents: {
      data: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateDocuments (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.documents.data = value
  },
  setDocuments (state, documents) {
    Object.assign(state.documents, documents)
  }
}

const actions = {
  resetStateDocuments ({ commit }) {
    commit('resetStateDocuments')
  },
  setData ({ commit, dispatch }, value) {
    commit('setData', value)
    dispatch('saveDocuments')
  },
  loadDocuments ({ commit, state, rootGetters }) {
    axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/server_document', { withCredentials: true })
      .then((response) => {
        commit('setData', response.data.data.documents)
      })
      .catch(() => {
        commit('setData', [])
      })
  },
  saveDocuments ({ state }) {
    LocalStorage.set('documents', state.documents)
  }
}

const getters = {
  documents: state => {
    return state.documents.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
