import VueMatomo from 'vue-matomo'
import Vue from 'vue'

export default async ({ router }) => {
  if (process.env.MATOMO_ENABLED === 1) {
    Vue.use(VueMatomo, {
      host: process.env.MATOMO_HOST,
      siteId: process.env.MATOMO_SITE_ID,
      trackerFileName: process.env.MATOMO_TRACKER_FILE,
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      debug: false
    })
  }
}
