const LIMITED = [
  'npa.syncro_credential_limited',
  'npa.check_select_all_disabled',
  'npa.routes_info_bandi_disabled',
  'npa.routes_tender_result_disabled',
  'npa.routes_supporto_tecnico_disabled',
  'npa.table_info_bandi_hidden',
  'npa.table_tender_result_hidden',
  'npa.chip_query_timeline_visible',
  'npa.button_upgrade_visible',
  'npa.button_prefer_hidden',
  'npa.banner_group_2_visible',
  'npa.videoguide_group_0_visible'
]
const NOLIMITED = [
  'npa.syncro_credential',
  'npa.syncro_data',
  'npa.header_blu_enabled',
  'npa.select_prefer_visible',
  'npa.button_subscription_expired_visible',
  'npa.button_renew_now_visible',
  'npa.banner_group_0_visible',
  'npa.banner_group_1_visible',
  'npa.videoguide_group_0_visible'
]

const USERFREE = [
  'npa.user_subscription_free'
]

const USERSTART = [
  'npa.header_blu_enabled',
  'npa.chip_user_start',
  'npa.user_subscription_start',
  'npa.button_subscription_expired_visible',
  'npa.button_renew_now_visible'
]

const USERPLUS = [
  'npa.user_subscription_plus',
  'npa.chip_user_plus',
  'npa.chip_query_timeline_visible',
  'npa.button_upgrade_visible'
]

const USERUNLIMITED = [
  'npa.user_subscription_unlimited',
  'npa.chip_user_unlimited',
  'npa.no_dialog_queries'
]

const BENCHMARK = [
  'npa.user_subscription_benchmark',
  'npa.chip_user_benchmark',
  'npa.no_dialog_queries'
]
const getDefaultState = () => {
  return {
    permissions: {
      data: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStatePermissions (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.permissions.data = value
  }
}

const actions = {
  resetStatePermissions ({ commit }) {
    commit('resetStatePermissions')
  },
  setData ({ commit, dispatch }, value) {
    commit('setData', value)
    dispatch('filters/setConditions', true, { root: true })
  },
  setGroup ({ state, commit, dispatch }, value) {
    switch (value) {
      case 'free':
        commit('setData', [...LIMITED, ...USERFREE])
        break
      case 'start':
        commit('setData', [...LIMITED, ...USERSTART])
        break
      case 'plus':
        commit('setData', [...NOLIMITED, ...USERPLUS])
        break
      case 'unlimited':
        commit('setData', [...NOLIMITED, ...USERUNLIMITED])
        break
      case 'benchmarkON':
        commit('setData', [...state.permissions.data, ...BENCHMARK])
        break
      case 'benchmarkOFF':
        let stateData = state.permissions.data
        let newArray = stateData.filter(element => !BENCHMARK.includes(element))
        commit('setData', newArray)
        break
    }
  }
}

const getters = {
  getAllPermissions: state => {
    return Object.values(state.permissions.data)
  },
  getPermission: (state) => (key) => {
    return (Object.values(state.permissions.data)).includes(key)
  },
  checkPermissionDisableHidden: (state, getters) => (key) => {
    return !(getters.getPermission(key + '_disabled') || getters.getPermission(key + '_hidden'))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
