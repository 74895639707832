import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('benchmarksa', [
      'loadData',
      'loadDetailsBenchmarkSA'
    ])
  },
  computed: {
    ...mapGetters('benchmarksa', [
      'getBenchmarkSA',
      'getLoadingBenchmarksa',
      'getLoadedBenchmarksa',
      'getDetailsBenchmarkSA',
      'getLoadDetailsSA'

    ])
  }
}
