import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('benchmark', [
      'loadData'
    ])
  },
  computed: {
    ...mapGetters('benchmark', [
      'getBenchmark',
      'getLoadingBenchmark',
      'getLoadedBenchmark'
    ])
  }
}
