import { i18n } from 'boot/i18n'

const routes = [
  {
    path: '/',
    component: () => import('layouts/Layout.vue'),
    meta: {
      auth: false
    },
    children: [
      {
        name: 'auth',
        path: i18n.t('routes.auth'),
        component: () => import('pages/PageAuth.vue')
      }
    ]
  },
  {
    name: 'supplier',
    path: '/supplier',
    component: () => import('layouts/Layout.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        name: 'home',
        path: i18n.t('routes.home'),
        component: () => import('pages/PageHome.vue'),
        meta: {
          permission: 'npa.routes_home'
        }
      },
      {
        name: 'cruscotto',
        path: i18n.t('routes.cruscotto'),
        component: () => import('pages/PageCruscotto.vue'),
        meta: {
          permission: 'npa.routes_cruscotto'
        }
      },
      {
        name: 'iscrizione',
        path: i18n.t('routes.iscrizione'),
        component: () => import('pages/PageIscrizione.vue'),
        meta: {
          loadForm: true,
          permission: 'npa.routes_iscrizione'
        }
      },
      {
        name: 'sincronizzazione',
        path: i18n.t('routes.sincronizzazione'),
        component: () => import('pages/PageSyncro.vue'),
        meta: {
          permission: 'npa.routes_sincronizzazione'
        }
      },
      {
        name: 'area_comunicazioni',
        path: i18n.t('routes.area_comunicazioni'),
        component: () => import('pages/PageAreaComunicazioni.vue'),
        meta: {
          permission: 'npa.routes_area_comunicazioni'
        }
      },
      {
        name: 'albi_fornitori',
        path: i18n.t('routes.albi_fornitori'),
        component: () => import('pages/PageInstallations.vue'),
        meta: {
          permission: 'npa.routes_albi_fornitori'
        }
      },
      {
        name: 'bandi_gara',
        path: i18n.t('routes.bandi_gara'),
        component: () => import('pages/PageTenders.vue'),
        meta: {
          permission: 'npa.routes_bandi_gara'
        }
      },
      {
        name: 'info_bandi',
        path: i18n.t('routes.info_bandi'),
        component: () => import('pages/PageInfoBandi.vue'),
        meta: {
          permission: 'npa.routes_info_bandi'
        }
      },
      {
        name: 'benchmark',
        path: i18n.t('routes.benchmark'),
        component: () => import('pages/PageBenchmark.vue'),
        meta: {
          permission: 'npa.routes_benchmark'
        }
      },
      {
        name: 'benchmark-contr',
        path: i18n.t('routes.benchmark-contr'),
        component: () => import('pages/PageBenchmarkChild.vue'),
        meta: {
          permission: 'npa.routes_benchmark_child_contr',
          type: 'contr'
        }
      },
      {
        name: 'benchmark-oe',
        path: i18n.t('routes.benchmark-oe'),
        component: () => import('pages/PageBenchmarkChild.vue'),
        meta: {
          permission: 'npa.routes_benchmark_child_oe',
          type: 'oe'
        }
      },
      {
        name: 'benchmark-sa',
        path: i18n.t('routes.benchmark-sa'),
        component: () => import('pages/PageBenchmarkChild.vue'),
        meta: {
          permission: 'npa.routes_benchmark_child_sa',
          type: 'sa'
        }
      },
      {
        name: 'tender_result',
        path: i18n.t('routes.tender_result'),
        component: () => import('pages/PageTenderResult.vue'),
        meta: {
          permission: 'npa.routes_tender_result'
        }
      },
      {
        name: 'internal_faq',
        path: i18n.t('routes.internal_faq'),
        component: () => import('pages/PageFaq.vue'),
        meta: {
          permission: 'npa.routes_internal_faq'
        }
      },
      {
        name: 'supporto_tecnico',
        path: i18n.t('routes.supporto_tecnico'),
        meta: {
          permission: 'npa.routes_supporto_tecnico'
        }
      },
      {
        name: 'regolamento',
        path: i18n.t('routes.regolamento'),
        component: () => import('pages/PageRegolamento.vue'),
        meta: {
          permission: 'npa.routes_regolamento'
        }
      },
      {
        name: 'area_notifiche',
        path: i18n.t('routes.area_notifiche'),
        component: () => import('pages/PageNotifiche.vue'),
        meta: {
          permission: 'npa.routes_area_notifiche'
        }
      },
      {
        name: 'videoguide_manuali',
        path: i18n.t('routes.videoguide_manuali'),
        component: () => import('pages/PageVideoguideManuali.vue'),
        meta: {
          permission: 'npa.routes_videoguide'
        }
      },
      {
        name: 'user_modifier',
        path: i18n.t('routes.user_modifier'),
        component: () => import('pages/PageUserModifier.vue'),
        meta: {
          permission: 'npa.routes_user_modifier'
        }
      }
    ]
  },
  // Always leave this as last one
  {
    name: '404',
    path: '*',
    component: () => import('pages/Error404.vue')
  }

]

export default routes
