import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('supplierForms', [
      'setStepsSupplierForms',
      'setLoadingSupplierForms',
      'setSubmitButtonSupplierForms',
      'loadSupplierForms',
      'saveSupplierForms'
    ])
  },
  computed: {
    ...mapGetters('supplierForms', [
      'supplierForms',
      'getLoadingSupplierForms'
    ]),
    stepsSupplierForms: {
      get () {
        return this.supplierForms.steps
      }
    },
    submitButtonSupplierForms: {
      get () {
        return this.supplierForms.submitButton
      },
      set (value) {
        this.setSubmitButtonSupplierForms(value)
      }
    },
    countriesSupplierForms: {
      get () {
        return this.supplierForms.countries
      }
    }
  }
}
