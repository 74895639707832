import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('auth', [
      'loginUser',
      'getJwtLogin',
      'logoutUser'
    ])
  },
  computed: {
    ...mapGetters('auth', [
      'getStateAuth',
      'getAuthLoggedIn'
    ])
  }
}
