import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('syncro', [
      'setSyncro',
      'setDeSyncro',
      'setNewToken',
      'setSyncroProgress',
      'setDeSyncroProgress',
      'setDeSyncroIdPlatform',
      'setNewTokenProgress',
      'setNewTokenIdPlatform',
      'sendSyncroCredential',
      'sendDeSyncroCredential',
      'sendSyncroSubscription',
      'sendNewToken'
    ])
  },
  computed: {
    ...mapGetters('syncro', [
      'getNewToken',
      'getSyncroProgress',
      'getDeSyncroProgress',
      'getDeSyncroIdPlatform',
      'getNewTokenProgress',
      'getNewTokenIdPlatform'
    ]),
    syncroProgress: {
      get () {
        return this.getSyncroProgress
      },
      set (value) {
        this.setSyncroProgress(value)
      }
    },
    deSyncroProgress: {
      get () {
        return this.getDeSyncroProgress
      },
      set (value) {
        this.setDeSyncroProgress(value)
      }
    },
    deSyncroIdPlatform: {
      get () {
        return this.getDeSyncroIdPlatform
      },
      set (value) {
        this.setDeSyncroIdPlatform(value)
      }
    },
    newTokenProgress: {
      get () {
        return this.getNewTokenProgress
      },
      set (value) {
        this.setNewTokenProgress(value)
      }
    },
    newTokenIdPlatform: {
      get () {
        return this.getNewTokenIdPlatform
      },
      set (value) {
        this.setNewTokenIdPlatform(value)
      }
    }
  }
}
