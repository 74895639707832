import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('user', [
      'loadUser',
      'setPreferUser',
      'setIsPlusDataStart',
      'setIsPlusDataEnd',
      'setPreferLanguage',
      'setPaperboyReaded',
      'setBenchmark190MaxRequests'
    ])
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'getUserPreference',
      'getPreferLanguage',
      'getUserProfile',
      'getUserPlus',
      'getPreferPlatform',
      'getPreferTender',
      'getMaxRowMessages',
      'getMaxRowTenders',
      'getCountSelectableSyncroPlatform',
      'getRemainingSyncs',
      'isCountSelectableSyncroPlatform',
      'getInstallationSyncroPlatform',
      'getPercentageComplete',
      'getAvailableRequests',
      'getRemainingRequests',
      'getActiveServiceExpirationDate',
      'getBenchmarkExpirationDate',
      'getBenchmark190Requests'
    ]),
    IsPlusDataStart: {
      get () {
        return this.getUserPlus.IsPlusDataStart
      }
    },
    IsPlusDataEnd: {
      get () {
        return this.getUserPlus.IsPlusDataEnd
      }
    },
    getPreferUser: {
      get () {
        return this.user.data
      }
    },
    preferLanguage: {
      get () {
        return this.getPreferLanguage
      },
      set (value) {
        this.setPreferLanguage(value)
      }
    }
  }
}
