import { date } from 'quasar'
import { i18n } from 'boot/i18n.js'

import settings from 'mixins/mixin-settings.js'
import user from 'mixins/mixin-user.js'
import installations from 'mixins/mixin-installations.js'
import supplierForm from 'mixins/mixin-supplier-forms.js'
import auth from 'mixins/mixin-auth.js'
import dialogs from 'mixins/mixin-dialogs.js'
import syncro from 'mixins/mixin-syncro.js'
import tenders from 'mixins/mixin-tenders.js'
import permissions from 'mixins/mixin-permissions'
// NEW
import filters from 'mixins/mixin-filters.js'
import paginations from 'mixins/mixin-paginations.js'
import benchmark from 'mixins/mixin-benchmark.js'
import benchmarkcontr from 'mixins/mixin-benchmark-contr.js'
import benchmarkoe from 'mixins/mixin-benchmark-oe.js'
import benchmarksa from 'mixins/mixin-benchmark-sa.js'
import tenderResult from 'mixins/mixin-tenderResult.js'
import infoBandi from 'mixins/mixin-infoBandi.js'
import messages from 'mixins/mixin-messages.js'

export default {
  mixins: [
    settings,
    installations,
    supplierForm,
    auth,
    tenders,
    messages,
    dialogs,
    syncro,
    permissions,
    user,
    filters,
    paginations,
    benchmark,
    benchmarkcontr,
    benchmarkoe,
    benchmarksa,
    tenderResult,
    infoBandi
  ],
  data () {
    return {
      default_notify_params: {
        position: 'top',
        color: 'blue',
        timeout: 5000,
        message: '',
        icon: 'info'
      },
      dafault_icon_title: {
        name: 'info',
        style: 'font-size: 1em;',
        class: 'text-secondary q-pt-sm'
      }
    }
  },
  methods: {
    stylePage (offset) {
      return { minHeight: offset ? `calc(95vh - ${offset}px)` : '100vh' }
    },
    manageScreen () {
      if (this.$q.screen.xs) {
        return 'col-12'
      }
      if (this.$q.screen.sm) {
        return 'col-6'
      }
      if (this.$q.screen.md) {
        return 'col-4'
      }
      return 'col-3'
    },
    openLink (item) {
      if (item.external === true) {
        if (this.$q.platform.is.desktop) {
          window.open(this.$t(item.to))
        } else {
          window.location.assign(this.$t(item.to))
        }
      } else {
        this.$router.push(this.generateUrl(this.$t(item.to))).catch(() => {
        })
      }
    },
    menuCheckLogged (item) {
      if (this.getPermission(item.permission + '_hidden')) {
        return false
      }
      if (item.auth === 'both') {
        return true
      } else {
        return !((!this.getAuthLoggedIn && item.auth) || (this.getAuthLoggedIn && !item.auth))
      }
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    generateUrl (to, hash = null, querys = null) {
      let url = to
      let n = 0
      let objQuerys = {}
      if (querys) {
        if (Object.keys(this.$route.query).length !== 0) {
          for (let routeQuery in this.$route.query) {
            if (this.$route.query.hasOwnProperty(routeQuery)) {
              objQuerys[routeQuery] = this.$route.query[routeQuery]
            }
          }
        }
        for (let query in querys) {
          if (querys.hasOwnProperty(query)) {
            objQuerys[query] = querys[query]
          }
        }
        for (let objQuery in objQuerys) {
          n++
          if (objQuerys.hasOwnProperty(objQuery)) {
            if (n === 1) {
              url += '?'
            } else {
              url += '&'
            }
            url += objQuery + '=' + objQuerys[objQuery]
          }
        }
      }
      if (hash) {
        url += hash
      }
      return url
    },
    valuesIsObject (value) {
      if (value !== null) {
        return typeof value === 'object' ? value.value : value
      } else {
        return value
      }
    },
    isArrayOrObjectDefinedNonEmpty (variable) {
      // Controlla se la variabile è definita e non è nullo
      if (variable == null) {
        return false
      }

      // Controlla se la variabile è un array o un oggetto
      if (!Array.isArray(variable) && typeof variable !== 'object') {
        return false
      }

      // Controlla se la variabile ha almeno una proprietà
      return Object.keys(variable).length !== 0
    },
    formatText (value, numberLength = 75) {
      if (typeof value === 'string' && value.length >= numberLength) {
        return value.substring(0, numberLength).trim() + '..'
      } else {
        return value
      }
    },
    formatSelectText (value, values) {
      if (value) {
        return this.i18n(values[this.valuesIsObject(value)])
      }
    },
    capitalizeFirstLetter (string = '') {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    formatOptionGroupText (value, values) {
      if (value !== undefined && Array.isArray(value['value'])) {
        let textReturn = ''
        value['value'].forEach(function (key) {
          let row = values.filter(value => (value.value === key))
          textReturn += row[0].label + ', '
        })
        return textReturn
      }
    },
    formatCheckBoxText (value) {
      if (value) {
        return this.valuesIsObject(value) ? this.i18n('lbl_yes') : this.i18n('lbl_no')
      }
    },
    i18n: function (values, params = {}) {
      return i18n.t(values, params)
    },
    manageOptionNewSelectValues: function (values) {
      let listOfObjects = []
      for (let key in values) {
        let label = this.i18n(values[key].name)
        listOfObjects.push(
          {
            value: values[key].code,
            label: label
          }
        )
      }
      return listOfObjects
    },
    manageOptionValues: function (values) {
      let listOfObjects = []
      for (let key in values) {
        let label = this.i18n(values[key])
        listOfObjects.push(
          {
            label: label,
            value: key
          }
        )
      }
      return listOfObjects
    },
    manageOptionValuesJson: function (values) {
      let listOfObjects = []
      for (let key in values) {
        listOfObjects.push(
          {
            label: this.i18n(values[key].label),
            value: values[key].status,
            disable: values[key].disable,
            icon: values[key].icon,
            onClick: values[key].onClick
          }
        )
      }
      return listOfObjects
    },
    getTypeComponent (type) {
      if (this.isUndefined(type)) {
        type = 'text'
      } else if (type === 'partial') {
        type = 'table'
      }

      return type[0].toUpperCase() + type.slice(1)
    },
    currentComponent: function (type) {
      return this.getTypeComponent(type) + 'Field'
    },
    currentCellComponent: function (type) {
      return this.getTypeComponent(this.getTypeForCellTable(type)) + 'Cell'
    },
    currentListComponent: function (string) {
      return this.getTypeComponent(string) + 'Lists'
    },
    getTypeForCellTable (type) {
      if (this.isFieldImport(type)) {
        return 'import'
      }
      if (this.isFieldDate(type)) {
        return 'date'
      }
      if (this.isFieldDocument(type)) {
        return 'document'
      }
      if (this.isFieldSelect(type)) {
        return 'select'
      }
      if (this.isFieldText(type)) {
        return 'text'
      }
      return type
    },
    isFieldImport (type) {
      let typeArray = [
        'import',
        'importCategorieLP',
        'ImportCategorieLP',
        'percentualeComponenti'
      ]
      return typeArray.includes(type)
    },
    isFieldDate (type) {
      let typeArray = [
        'date',
        'dateCategorieLP'
      ]
      return typeArray.includes(type)
    },
    isFieldDocument (type) {
      let typeArray = [
        'document',
        'docCertificazioneSOACategorieLP',
        'docDichiarazioniCategorieLP'
      ]
      return typeArray.includes(type)
    },
    isFieldSelect (type) {
      let typeArray = [
        'select',
        'selectLivelloCategorieLP'
      ]
      return typeArray.includes(type)
    },
    isFieldText (type) {
      let typeArray = [
        'text',
        'email',
        'year',
        'checkboxListSpecializzazioni',
        'textarea',
        'textCategorieLP'
      ]
      return typeArray.includes(type)
    },
    checkDifferenceDate (value, days = 30) {
      let dateDiff = this.differenceDate(value)
      return dateDiff <= days
    },
    differenceDate (date1, today = new Date(), unit = 'days') {
      return date.getDateDiff(date1, today, unit)
    },
    checkDifferenceDateWithFormatString (value, days) {
      let formattedString = this.$options.filters.formatDate(value, 'YYYY/MM/DD')
      return this.checkDifferenceDate(formattedString, days)
    },
    getState (value, id, field) {
      return this.$store.state[value][value]['data'][id][field]
    },
    GetFromColumns (columns, key, value) {
      return columns.filter(column => (column[key] === value))
    },
    GetValuesFromColumns (columns, key, value) {
      let row = this.GetFromColumns(columns, key, value)
      if (!this.isUndefined(row)) {
        return row[0]['values']
      }
    },
    isEmptyObject (someObject) {
      return someObject.constructor === Object && Object.entries(someObject).length === 0
    },
    showNotify (notif) {
      let values = { ...this.default_notify_params, ...notif }
      this.$q.notify(values)
    },
    showWarning (notif) {
      let values = { color: 'red', icon: 'warning' }
      values = { ...values, ...notif }
      this.showNotify(values)
    },
    showSuccess (notif) {
      let values = { color: 'green', icon: 'done' }
      values = { ...values, ...notif }
      this.showNotify(values)
    },
    bytesToSize (bytes) {
      const sizes = [
        'Bytes',
        'KB',
        'MB',
        'GB',
        'TB'
      ]
      if (bytes === 0) {
        return 'n/a'
      }
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) {
        return `${bytes} ${sizes[i]})`
      }
      return `${(bytes / (1024 ** i)).toFixed(0)} ${sizes[i]}`
    },
    getLabelWithRequired (label, type) {
      return label !== null ? label + (type ? '*' : '') : ''
    },
    disabledRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.readonly = true
      }
    },
    disabledAndClearRule (ref) {
      this.clearRule(ref)
      this.disabledRule(ref)
    },
    DisableDialog (disabled) {
      if (disabled) {
        this.forUserPlusDialog = true
      }
    },
    enabledRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.readonly = false
      }
    },
    clearRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.field.value = ''
      }
    },
    hideRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.show = false
      }
    },
    showRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.show = true
      }
    },
    requiredRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.required = true
      }
    },
    optionalRule (ref) {
      if (!this.isUndefined(ref)) {
        ref.required = false
      }
    },
    fieldHasRules (field, value) {
      if (field.rules !== undefined) {
        if (field.rules.type === 'value') {
          if (field.rules.values[value] !== undefined) {
            for (let key in field.rules.values[value]) {
              let rules = field.rules.values[value][key].rules
              this.$emit('hasRules', key, rules)
            }
          }
        } else if (field.rules.type === 'maxlength') {
          if (field.rules.values !== undefined) {
            for (let maxvalue in field.rules.values) {
              if (value.length <= maxvalue) {
                for (let key in field.rules.values[maxvalue]) {
                  let rules = field.rules.values[maxvalue][key].rules
                  this.$emit('hasRules', key, rules)
                }
                break
              }
            }
          }
        }
      }
    },
    consoleLog (value) {
      // eslint-disable-next-line no-console
      console.log(value)
    },
    getTipologieCampiObbligatoriEsclusi: function (values) {
      return [
        'actionButton',
        'hidden'
      ]
    },
    checkValidity: function (form, nomeCampo, columnType) {
      return form[nomeCampo] !== undefined &&
        (
          (
            form[nomeCampo].value === null ||
            form[nomeCampo].value === '' ||
            this.checkPercentualeComponenti(form, nomeCampo, columnType) ||
            this.checkCheckbox(form, nomeCampo, columnType)
          ) ||
          (Array.isArray(form[nomeCampo]) && form[nomeCampo].length === 0) ||
          (Array.isArray(form[nomeCampo].value) && form[nomeCampo].value.length === 0)

        )
    },
    checkPercentualeComponenti: function (form, nomeCampo, columnType) {
      return (form['cessati'] === undefined || (form['cessati'] !== undefined && form['cessati'].value === false)) &&
        form['tipologia'] !== undefined &&
        form['tipologia'].value === 'soc' &&
        columnType === 'percentualeComponenti' &&
        (form[nomeCampo].value <= 0 || form[nomeCampo].value > 100)
    },
    checkCheckbox: function (form, nomeCampo, columnType) {
      return columnType === 'checkbox' && form[nomeCampo].value === false
    },
    // verifica che i campi required siano compilati
    getFieldsErrorForm (form, colonneTabella, campiObbligatoriDialog = undefined, campiPersonalizzatiDialog = undefined) {
      let fieldsError = []
      let vm = this
      if (campiObbligatoriDialog !== undefined) {
        // verifica se campiObbligatoriDialog è '*' (tutti i campi sono obbligatori), altrimenti utilizza le chiavi indicate in campiObbligatoriDialog
        let campi = (campiObbligatoriDialog.indexOf('*') >= 0) ? Object.keys(form) : campiObbligatoriDialog
        campi.forEach(function (nomeCampo, index, arr) {
          let columnType = ''
          if (nomeCampo.includes('|')) {
            nomeCampo = nomeCampo.split('|')
          } else {
            nomeCampo = [nomeCampo]
          }
          let pushError = []

          nomeCampo.forEach(function (nc, index, arr) {
            colonneTabella.forEach(function (colonna, index, arr) {
              if (colonna.name === nc) {
                columnType = colonna.type
              }
            })
            // extraControlli altri campi condizionali che dipendono da variabili (ad esempio per i componenti se sono di persona fisica o persona giuridica)
            const extraControlli = (campiPersonalizzatiDialog !== undefined) ? campiPersonalizzatiDialog.indexOf(nc) >= 0 : 1

            let valoreValido = vm.checkValidity(form, nc, columnType)

            pushError.push(extraControlli && vm.getTipologieCampiObbligatoriEsclusi().indexOf(columnType) < 0 && (valoreValido))
          }) // end nomeCampo.forEach
          if (pushError.every(Boolean)) {
            nomeCampo.forEach(function (nc, index, arr) {
              fieldsError.push(nc)
            })
          }
        })
      }
      return fieldsError
    },
    getFileIcon (filename) {
      let name = 'far fa-'
      let color = 'grey-9'
      if (filename !== undefined) {
        const ext = filename.split('.').pop()

        switch (ext) {
          case 'pdf':
            name += 'file-pdf'
            color = 'red-10'
            break
          case 'p7m':
          case 't7d':
            name += 'file-alt'
            color = 'amber-8'
            break
          case 'zip':
          case '7z':
          case 'tar':
            name += 'file-archive'
            color = 'orange-10'
            break
          case 'doc':
          case 'docx':
            name += 'file-word'
            color = 'blue-10'
            break
          case 'xls':
          case 'xlsx':
            name += 'file-excel'
            color = 'green'
            break
          case 'jpg':
          case 'jpeg':
          case 'gif':
          case 'bmp':
            name += 'file-image'
            color = 'indigo-7'
            break
          default:
            name += 'file'
        }
        return { name: name, color: color }
      }
      return { name: 'far fa-file', color: color }
    },
    getIconNotify (type) {
      switch (type) {
        case 'tender_session_started':
          return { icon: 'fas fa-play-circle', class: 'text-primary-light' }
        case 'tender_integrazione_documenti':
          return { icon: 'fas fa-plus', class: 'text-primary' }
        case 'new_public_tender':
          return { icon: 'fas fa-gavel', class: 'text-primary' }
        case 'tender_richiesta_offerta_migliorativa':
          return { icon: 'fas fa-folder-plus', class: 'text-teal' }
        case 'new_platform':
          return { icon: 'fas fa-sync-alt', class: 'text-n_lightblue' }
        case 'tender_exclusion_notice':
          return { icon: 'fas fa-user-slash', class: 'text-red' }
        case 'disabilitazione_utente':
          return { icon: 'fas fa-user-minus', class: 'text-grey' }
        case 'tender_invites':
          return { icon: 'fas fa-envelope-open-text', class: 'text-orange' }
        default:
          return { icon: 'notifications', class: 'text-primary' }
      }
    },
    getTitleNotify (type) {
      return this.i18n('lbl_notify_' + type)
    },
    getSocialFB () {
      return process.env.COMPANY_SOCIAL_FB
    },
    getSocialIN () {
      return process.env.COMPANY_SOCIAL_IN
    },
    getSocialTW () {
      return process.env.COMPANY_SOCIAL_TW
    }
  },
  computed: {
    localLang () {
      if (this.$q.lang.isoName === 'en-gb') {
        return 'en'
      }
      return this.$q.lang.isoName
    },
    countries () {
      return this.countriesSupplierForms
    }
  },
  filters: {
    formatDate (value, type = 'DD/MM/YYYY') {
      if (value) {
        return date.formatDate(typeof value === 'object' ? value.value : value, type)
      }
    },
    sortingText (first, second) {
      if (typeof first === 'string' && typeof second === 'string') {
        if (first.toLowerCase() < second.toLowerCase()) {
          return -1
        }
        if (first.toLowerCase() > second.toLowerCase()) {
          return 1
        }
      } else {
        return 0
      }
    }

  }
}
