import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('benchmarkcontr', [
      'loadDetailsBenchmarkContr'
    ])
  },
  computed: {
    ...mapGetters('benchmarkcontr', [
      'getDetailsBenchmarkContr',
      'getLoadDetailsContr'
    ])
  }
}
