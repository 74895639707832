import BackendAPI from '../classes/backendAPI'
import { url } from '../classes/settingsStatic'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    benchmarkcontr: {
      details: {},
      loadDetailsContr: false
    }
  }
}

const apiDetails = new BackendAPI({
  baseURL: url.ws_benchmark_gara
})

// initial state
const state = getDefaultState()

const mutations = {
  resetStateBenchmarkContr (state) {
    Object.assign(state, getDefaultState())
  },
  setDetailsBenchmarkContr (state, value) {
    state.benchmarkcontr.details = value
  },
  setLoadDetailsContr (state, value) {
    state.benchmarkcontr.loadDetailsContr = value
  }
}

const actions = {
  resetStateBenchmarkContr ({ commit }) {
    commit('resetStateBenchmarkContr')
  },
  loadDetailsBenchmarkContr ({ commit, rootState, dispatch }, id) {
    commit('setLoadDetailsContr', true)

    return apiDetails.get(id + '/info')
      .then((response) => {
        dispatch('user/loadBenchmark190', true, { root: true })
        commit('setDetailsBenchmarkContr', { ...response })
        commit('setLoadDetailsContr', false)
        return true
      })
      .catch(function (error) {
        const message = error.message
        const response = error.response

        if (response.status !== 499) {
          Vue.prototype.$q.dialog({
            component: 'DialogQueryFinished',
            parent: this,
            text: message
          })
          commit('setDetailsBenchmarkContr', {})
          commit('setLoadDetailsContr', false)
        }
        return false
      })
  }
}

const getters = {
  getDetailsBenchmarkContr: state => {
    return state.benchmarkcontr.details
  },
  getLoadDetailsContr: state => {
    return state.benchmarkcontr.loadDetailsContr
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
