import ConfigPaginations from 'config/ConfigPaginations.json'

const getDefaultState = () => {
  return JSON.parse(JSON.stringify(ConfigPaginations))
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStatePaginations (state) {
    state = getDefaultState()
  },
  resetForTypePagination (state, type) {
    Object.entries(ConfigPaginations[type]).map(([key, value]) => {
      state[type][key] = value
    })
  },
  resetForTypePaginationExceptLastFilter (state, type) {
    Object.entries(ConfigPaginations[type]).map(([key, value]) => {
      if (key !== 'lastFilterApplied' && key !== 'rowsPerPage') {
        state[type][key] = value
      }
    })
  },
  setPaginations (state, payload) {
    state[payload.type] = payload.value
  },
  setPaginationsKey (state, payload) {
    state[payload.type][payload.key] = payload.value
  },
  setLastFilterApplied (state, payload) {
    state[payload.type]['lastFilterApplied'] = payload.value
  },
  setPaginationForResponse (state, payload) {
    state[payload.type][payload.key] = payload.value
  },
  setTypeLoading (state, payload) {
    state[payload.type]['loading'] = payload.value
  }
}

const actions = {
  resetStatePaginations ({ commit }) {
    commit('resetStatePaginations')
  },
  resetForTypePagination ({ commit }, type) {
    commit('resetForTypePagination', type)
  },
  resetForTypePaginationExceptLastFilter ({ commit }, type) {
    commit('resetForTypePaginationExceptLastFilter', type)
  },
  setPaginations ({ commit }, payload) {
    commit('setPaginations', payload)
  },
  setPaginationsKey ({ commit }, payload) {
    commit('setPaginationsKey', payload)
  },
  setLastFilterApplied ({ commit }, payload) {
    commit('setLastFilterApplied', payload)
  },
  setPaginationForResponse ({ commit }, payload) {
    commit('setPaginationForResponse', payload)
  },
  setTypeLoading ({ commit }, payload) {
    commit('setTypeLoading', payload)
  }
}

const getters = {
  getPaginations: state => type => {
    return state[type]
  },
  getPaginationKey: state => payload => {
    return state[payload.type][payload.key]
  },
  getTypeLoading: state => payload => {
    return state[payload.type]['loading']
  },
  getLastFilterApplied: state => payload => {
    if (state[payload.type]['lastFilterApplied'] !== undefined) {
      return state[payload.type]['lastFilterApplied']
    } else {
      return { 'fakeFilterApplied': 'forOldTable' }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
